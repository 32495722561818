.registerAccountContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 55px 20px;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-image: url('/public/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.brandingContainerRegisterAccount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  margin: 0 auto;
  padding-top: 30px;
}

.brandingTitleRegisterAccount {
  font-family: Montserrat;
  font-size: 36px;
  color: #505050;
}
