.desktopItemsMenuContainer {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 100px 100px 100px 1fr;
  grid-template-areas: "menuItem1 menuItem2 menuItem3 .";
}

.logoContainer {
  display: flex;
  position: absolute;
  width: 70px;
  height: 56px;
  top: 0;
  left: 12px;
  justify-content: center;
  align-items: center;
  grid-area: logo;
}

.logoDesktop {
  width: 50px;
  height: 50px;
}

.menuOptionsDesktop {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c2c2c2;
}

.menuItem1 {
  grid-area: menuItem1;
}

.menuItem2 {
  grid-area: menuItem2;
}

.menuItem3 {
  grid-area: menuItem3;
}

.loginHeaderLabelDesktop {
  display: flex;
  position: absolute;
  height: 100%;
  width: fit-content;
  align-items: center;
  top: 0;
  right: 20px;
  color: #c2c2c2;
  font-weight: 400;
  cursor: pointer;
  grid-area: logOutLink;
}
