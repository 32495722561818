.containerMoviesAndTvSeriesDashboard {
  padding: 86px 0 50px 0;
  min-height: calc(100vh - 160px);
  width: 100%;
}

.alertText {
  overflow-y: hidden;
  text-align: center;
}

.rowStyles {
  margin: 0;
}

@media screen and (min-width: 1050px) {
  .containerMoviesAndTvSeriesDashboard {
    min-height: calc(100vh - 60px);
  }
}
