p,
h2 {
  margin: 0;
}

.contentDetailsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 160px);
  height: fit-content;
  padding-top: 56px;
  align-items: center;
  margin: 0 auto;
}

.contentDetailsGrid {
  display: grid;
  position: relative;
  width: 100%;
  height: fit-content;
  padding-bottom: 20px;
  margin-bottom: 20px;
  color: white;
  background-color: #2c2c2c;
  grid-template-columns: 70px 1fr 50px 50px;
  grid-template-rows: auto 60px auto 40px 45px auto;
  grid-template-areas:
    "poster poster poster poster"
    "yearReleaseAndRating yearReleaseAndRating shareContentDetails likeContentDetails"
    "title title title title"
    "genres genres genres genres"
    "overview overview overview overview"
    "descriptionText descriptionText descriptionText descriptionText";
}

.poster {
  grid-area: poster;
  width: 100%;
}

.title {
  display: -webkit-box;
  width: 100%;
  height: 100%;
  justify-content: left;
  padding: 0 20px;
  line-height: 30px;
  height: auto;
  max-height: 60px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  grid-area: title;
}

.yearReleaseAndRating {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  padding-left: 20px;
  grid-area: yearReleaseAndRating;
}

.ratingIcon {
  margin-left: 10px;
}

.ratingText {
  margin-left: 3px;
}

.shareIcons {
  width: 100%;
  height: 100%;
}

.shareCardContainerContentDetails {
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
  cursor: pointer;
  grid-area: shareContentDetails;
}

.shareCardIconContentDetais {
  position: relative;
  top: 1px;
  width: 22px;
  height: 22px;
  color: white;
}

.shareCardIconActiveContentDetais {
  position: relative;
  top: 1px;
  width: 22px;
  height: 22px;
  color: #007a78;
}

.shareOptionsContainerContentDetails {
  position: absolute;
  flex-direction: column-reverse;
  bottom: 60px;
  right: -50px;
  width: 100vw;
  height: 0;
  overflow: hidden;
  background-color: #007a78;
  transition: height 0.3s linear;
}

.shareOptionsContainerOpenContentDetails {
  height: 100px; /* Altura completa cuando está abierto */
}

.shareOptionContainerContentDetails {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #2c2c2c;
}

.whastappShareIconContentDetails {
  position: relative;
  top: 0px;
  width: 22px;
  height: 22px;
  color: white;
}

.twitterShareIconContentDetails {
  position: relative;
  top: 1px;
  width: 25px;
  height: 25px;
  color: white;
}

.likeContentDetails {
  margin: auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
  grid-area: likeContentDetails;
}

.genres {
  display: flex;
  align-items: center;
  grid-area: genres;
  text-align: left;
  padding-left: 20px;
}

.overview {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  grid-area: overview;
}

.descriptionText {
  grid-area: descriptionText;
  line-height: 25px;
  padding: 0 20px;
  font-family: Poppins;
  font-size: 15px;
}

.backButtonContainer {
  display: grid;
  width: 100px;
  grid-template-columns: 20px 80px;
  margin-bottom: 40px;
  color: black;
}

.backButtonIcon {
  position: relative;
  top: 3px;
  left: 0;
  width: 20px;
  height: 20px;
  color: black;
}

.backButtonText {
  color: black;
  font-size: 16px;
  width: fit-content;
  text-align: right;
}

@media screen and (min-width: 768px) {
  .contentDetailsContainer {
    padding: 126px 0 50px 0;
    overflow-x: hidden;
  }

  .contentDetailsGrid {
    display: grid;
    width: 697px;
    height: 434px;
    background-color: white;
    color: black;
    grid-template-columns: 288px 20px 1fr 26px 15px 28px 20px;
    grid-template-rows: 10px auto 12px 35px 35px 40px 1fr 20px;
    grid-template-areas:
      "poster . . . . . ."
      "poster paddingLeft title title title title paddingRight"
      "poster paddingLeft . . . . paddingRight"
      "poster paddingLeft yearReleaseAndRating shareContentDetails . likeContentDetails paddingRight"
      "poster paddingLeft genres genres genres genres paddingRight"
      "poster paddingLeft overview overview overview overview paddingRight"
      "poster paddingLeft descriptionText descriptionText descriptionText descriptionText paddingRight"
      "poster paddingLeft . . . . paddingRight";
    margin: 20px;
    border: 1px solid #929292;
    box-shadow: 2px 2px 3px 1px #6c6c6c;
    border-radius: 10px;
  }

  .poster {
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .title {
    display: flex;
    width: 100%;
    height: fit-content;
    font-weight: bold;
    font-size: 35px;
    align-content: center;
    padding: 0;
    display: -webkit-box;
    line-height: 50px;
    max-height: 100px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-area: title;
  }

  .yearReleaseAndRating {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    padding-left: 0;
    grid-area: yearReleaseAndRating;
  }

  .ratingIcon {
    margin-left: 10px;
  }

  .ratingText {
    margin-left: 3px;
  }

  .genres {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    padding: 0;
    grid-area: genres;
  }

  .overview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-family: Poppins;
    font-size: 18px;
    grid-area: overview;
  }

  .descriptionText {
    height: 100%;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    overflow-y: auto;
    grid-area: descriptionText;
    padding: 0;
  }

  .descriptionText::-webkit-scrollbar {
    width: 4px;
  }

  .descriptionText::-webkit-scrollbar-track {
    background: #c2c2c2;
  }

  .descriptionText::-webkit-scrollbar-thumb {
    background: #007a78;
  }

  .descriptionText {
    scrollbar-width: thin;
    scrollbar-color: #007a78 #c2c2c2;
  }

  .shareCardContainerContentDetails {
    position: relative;
    display: flex;
    justify-content: right;
    align-items: center;
    cursor: pointer;
    grid-area: shareContentDetails;
  }

  .shareCardIconContentDetais {
    position: relative;
    top: 1px;
    width: 22px;
    height: 22px;
    color: black;
  }

  .shareCardIconActiveContentDetais {
    position: relative;
    top: 1px;
    width: 22px;
    height: 22px;
    color: #007a78;
  }

  .shareOptionsContainerContentDetails {
    display: flex;
    position: absolute;
    flex-direction: row;
    bottom: 0px;
    right: 30px;
    width: 0px;
    height: 35px;
    overflow: hidden;
    background-color: white;
    transition: width 0.3s linear;
  }

  .shareOptionsContainerOpenContentDetails {
    width: 80px;
  }

  .shareOptionContainerContentDetails {
    position: relative;
    display: flex;
    height: 35px;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-top: none;
  }

  .whastappShareIconContentDetails {
    position: relative;
    top: 0px;
    width: 22px;
    height: 22px;
    color: #007a78;
  }

  .twitterShareIconContentDetails {
    position: relative;
    top: 1px;
    width: 25px;
    height: 25px;
    color: #007a78;
  }

  .likeContentDetails {
    margin: auto;
    width: 30px;
    height: 30px;
    cursor: pointer;
    grid-area: likeContentDetails;
  }

  .backButtonContainer {
    display: grid;
    width: 110px;
    grid-template-columns: 20px 90px;
    margin-bottom: 40px;
    color: black;
  }

  .backButtonIcon {
    position: relative;
    top: 4px;
    left: 0;
    width: 25px;
    height: 25px;
    color: black;
  }

  .backButtonText {
    padding-left: 5px;
    color: black;
    font-size: 20px;
    width: fit-content;
    text-align: right;
  }
}

@media screen and (min-width: 1050px) {
  .contentDetailsContainer {
    width: 100%;
    min-height: calc(100vh - 60px);
    height: fit-content;
  }
}
