.itemBody {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-bottom: 40px;
}

.card {
  display: grid;
  grid-template-columns: 288px;
  grid-template-rows: 432px 150px 42px;
  grid-template-areas: 'img' 'details' 'button';
  box-shadow: 3px 3px 6px 1px #4c4c4c;
  background-color: black;
  border: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  color: #ffffff;
  height: 624px;
}

.cardImg {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;

  cursor: pointer;
  grid-area: img;
}

.cardDetails {
  display: grid;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  grid-template-columns: 70px 1fr 27px 42px;
  grid-template-rows: 60px 90px;
  grid-template-areas: 'rating rating share like' 'title title title title' '. . . .';
  text-align: center;
  align-self: center;
  background-color: #2c2c2c;
  line-height: 26px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  grid-area: details;
}

.cardDetailsNoLogged {
  grid-template-columns: 70px 1fr 42px;
  grid-template-rows: 60px 90px;
  grid-template-areas: 'rating rating share' 'title title title' '. . .';
}

.shareIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.shareCardContainer {
  display: flex;
  justify-content: right;
  align-items: center;
  cursor: pointer;
  grid-area: share;
}

.shareCardIcon {
  position: relative;
  top: 1px;
  width: 22px;
  height: 22px;
  color: white;
}

.shareCardIconActive {
  position: relative;
  top: 1px;
  width: 22px;
  height: 22px;
  color: #007a78;
}

.shareOptionsContainer {
  position: absolute;
  left: -0px;
  flex-direction: column-reverse;
  bottom: 192px;
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: #007a78;
  transition: height 0.3s linear;
}

.shareOptionsContainerOpen {
  height: 88px; /* Altura completa cuando está abierto */
}

.shareOptionContainer {
  display: flex;
  height: 44px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #2c2c2c;
}

.whastappShareIcon {
  position: relative;
  top: 0px;
  width: 22px;
  height: 22px;
  color: white;
}

.twitterShareIcon {
  position: relative;
  top: 1px;
  width: 25px;
  height: 25px;
  color: white;
}

.cardBodyrating {
  width: 100%;
}

.cardBodyRatingContainer {
  grid-area: rating;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.cardBodyRatingIcon {
  position: relative;
  z-index: 2;
  width: 20px;
  height: 20px;
  top: -3px;
  left: 0;
}

.cardBodyRatingText {
  position: relative;
  top: -2px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 10px;
}

.cardBodyRatingNumber {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 10px;
}

.cardBodyLike {
  width: 29px;
  height: 29px;
  margin: auto;
  margin-right: 0;
  cursor: pointer;
  grid-area: like;
}

.cardBodyTitle {
  grid-area: title;
  margin: 0;
  text-align: left;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  max-height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* limita el texto a 2 líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardBodyTitle h5 {
  display: inline;
  margin: 0;
  cursor: pointer;
}

.detailsButton {
  display: flex;
  width: 100%;
  height: 44px;
  text-decoration: none;
  font-size: 18px;
  background-color: #007a78;
  align-items: center;
  justify-content: center;
  grid-area: button;
}

.detailsButton:hover {
  background-color: #f5e101;
  color: black;
}

.detailsButton:active {
  color: black;
}
