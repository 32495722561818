.seachBarMobileContainer {
  display: grid;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #007a78;
  grid-template-columns: 1fr 56px;
  grid-template-areas: "input icon";
  height: 56px;
  width: 100%;
}

.searchInputMobile {
  grid-area: input;
  height: 56px;
  width: 100%;
  padding: 16px 16px 16px 12px;
  margin: 0;
  cursor: text;
  border: none;
  color: #c2c2c2;
  background-color: #007a78;
  font-family: roboto;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.searchInputMobile:focus-visible {
  border: none;
  outline: none;
}

.searchInputMobile::placeholder {
  color: #c2c2c2;
}

.closeButtonSearchBarMobileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
}

.closeSearchIconMobile {
  grid-area: icon;
  margin: auto;
  color: #c2c2c2;
  width: 28px;
  height: 28px;
  font-weight: 900;
  cursor: pointer;
}
