.menuMobileContainer {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #2c2c2c !important;
}

.dropdownMenu {
  background-color: #2c2c2c !important;
}

.togglerButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 40px;
  border: 1px solid #4c4c4c;
}

.togglerButtonContainer {
  box-shadow: none !important;
}

.hamburgerIcon {
  width: 42px;
  height: 30px;
  color: white;
}

.navBarDesplegable {
  background-color: #2c2c2c !important;
}

header li {
  height: 45px;
}

.menuOptionsMobileContainer {
  margin-top: 12px;
  margin-bottom: 0px !important;
}

.menuOptionMobile {
  color: white !important;
}

.searchIconMobile {
  position: absolute;
  width: 25px;
  height: 25px;
  right: calc(50% - 13px);
  top: 18px;
  color: white;
  cursor: pointer;
}

.loginHeaderLabelMobile {
  position: absolute;
  top: 18px;
  right: 14px;
  color: white;
  font-weight: 400;
  cursor: pointer;
}
