.searchBarDesktopContainer {
  display: grid;
  position: absolute;
  top: 12px;
  right: calc(50% - 220px);
  width: 440px;
  height: 32px;
  margin: auto;
  grid-template-columns: 400px 40px;
  grid-template-areas: "input icon";
  background-color: #c2c2c2;
  border-radius: 4px;
}

.searchInputDesktop {
  grid-area: input;
  height: 32px;
  width: 100%;
  padding: 6px 0px 6px 8px;
  margin: 0;
  cursor: text;
  border: none;
  color: #c2c2c2;
  font-family: roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  background-color: #007a78;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.searchInputDesktop:focus-visible {
  border: none;
  outline: none;
}

.searchInputDesktop::placeholder {
  color: #c2c2c2;
}

.searchIconDesktop {
  grid-area: icon;
  margin: auto;
  color: black;
  width: 24px;
  height: 24px;
  font-weight: 900;
  cursor: pointer;
}
