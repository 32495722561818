header {
  position: fixed;
  display: flex;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 56px;
  background-color: #2c2c2c !important;
  z-index: 3;
  user-select: none;
}
