.form-container {
  position: relative;
  width: 100%;
  max-width: 579px;
  height: fit-content;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 20px;
  background-color: rgba(9, 0, 045, 0.5);
  margin: 0 auto;
  padding: 60px;
}

.formTitle {
  width: 100%;
  left: 0px;
  height: fit-content;
  font-family: Poppins;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #cbcbcb;
  margin-top: 22px;
}

.loginAndRegisterForm {
  position: relative;
  width: 100%;
  height: fit-content;
  border: none;
  margin-top: 35px;
}

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 57px;
  max-width: 459px;
  width: 100%;
  border-radius: 8px;
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: 2;
}

.inputForm {
  display: block;
  border: none;
  background: none;
  background-color: #302858;
  color: #cbcbcb;
  width: 100%;
  height: 100%;
  font-family: poppins;
  font-size: 18px;
  font-weight: 300;
  border-radius: 10px;
  padding: 0 40px 0 20px;
}

.inputForm:focus-visible {
  outline: none;
}

.inputForm::placeholder {
  color: #cbcbcb;
}

.inputIcon {
  margin: auto;
  color: white;
  width: 18px;
  height: 18px;
}

.inputAlerts {
  display: flex;
  align-items: flex-start;
  color: #ff0400;
  font-size: 14px;
  height: 23px;
  width: calc(100% - 60px);
  margin-left: 32px;
  padding-left: 3px;
}

.showPasswordIcon {
  position: absolute;
  top: 18px;
  right: 15px;
  width: 22px;
  height: 22px;
  color: #cbcbcb;
  cursor: pointer;
}

.submitButton {
  display: block;
  cursor: pointer;
  border: none;
  width: 100%;
  max-width: 459px;
  height: 59px;
  border-radius: 10px;
  background: #7460e7;
  color: #cbcbcb;
  font-size: 17px;
  font-family: poppins;
  font-weight: 600;
}

.iconAndTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.submitButtonRegisterAccount {
  background-color: #cbcbcb;
}

.createAccountLink {
  display: block;
  text-decoration: underline;
  text-align: center;
  font-family: poppins;
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
  color: #cbcbcb;
  cursor: pointer;
  padding-bottom: 15px;
}

.createAccountLink:visited {
  color: #395173;
}

.createAccountLink:hover {
  color: #395173;
}

.waiting {
  background-color: black;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@media screen and (max-width: 509px) {
  .submitButton {
    height: 45px;
  }

  .inputForm {
    height: 50px;
  }

  .form-container {
    padding: 40px 20px;
  }
}
