* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
  overflow-x: visible;
  font-family: Poppins;
}

.hidden {
  display: none;
}

.my-popup-class {
  overflow-y: hidden;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
}

a:active {
  color: white;
}

body {
  background: rgb(96, 78, 129);
  background: linear-gradient(
    145deg,
    rgba(96, 78, 129, 1) 24%,
    rgba(76, 26, 129, 1) 43%,
    rgb(62, 133, 255) 79%
  );
  background-blend-mode: multiply;
  background-attachment: fixed; /* Fija el fondo */
  will-change: background; /* Optimiza el renderizado */
}
